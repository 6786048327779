<template>
    <div class="search__container">
        <div class="search__image">
            <img :src="require('../../../assets/images/illustrations/illustration-5.svg')" alt="" class="src">
        </div>
        <Search customClass="input-wrap__large"/>
    </div>
</template>

<script>
import Search from '@/common/components/Search.vue';

export default {
  name: 'SearchWidget',
  components: {
    Search,
  },
};
</script>

<style lang="scss">
.search {
    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__heading {
        margin-bottom: 2.2rem;
    }

    &__image {
        .src {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
